/*CSS for Login Page*/
.error-msg {
    color: rgb(255, 0, 0);
    padding-top: 1%;
    max-width: 300px;
    overflow-wrap: break-word;
    margin-top: 5px;
    font-size: small;
}
.success-msg {
    color: green;
    padding-top: 1%;
    max-width: 300px;
    overflow-wrap: break-word;
    margin-top: 5px;
    font-size: small;
}

.login-text {
    color: rgb(0, 0, 0);
}

#main-login-container {
    max-width: 30%;
    min-width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    /* padding: 1%; */
    /* padding-top: 3%; */
}

#login-container {
    width: 100%;
    min-width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #CFCFCF;
    border-radius: 10px;
    padding: 30px;
    margin-top: 2%;
    margin-bottom: 0.5%;
}

#login-btn-container{
    width: 100%;
    height: 13%;
    padding: 0%;
    margin-top: 3%;
}

#formGroupExampleInput, #formGroupExampleInput2{
    border-radius: 0px;
    background-color: white;
    border-color: #CFCFCF;
}

#login-btn{
    width: 100%;
    margin-top: 5%;
    background-color: #43ABBB;
    border-color: #43ABBB;
    border-radius: 0px;
}
#login-btn:hover{
    background-color: #107888;
    border-color: #107888;
    text-decoration: underline;

}

#create-account-container {
    width: 100%;
    min-width: fit-content;
    height: 7%;
    display: flex;
    justify-content: center;
    background-color: #CFCFCF;
    border-radius: 10px;
    margin-top: 1%;
    padding: 13px;
}

#forgot-pass-link{
    display: flex;
    justify-content: end;
    margin: 1%;
}
#forgot-pass-link:hover{
    text-decoration: underline;
}

#sign-up-link {
    margin-left: 5px;
    color: #0085FF;
    font-weight: 600;
}
#sign-up-link:hover {
    text-decoration: underline;
}

/* CSS for Mobile View */
@media only screen and (max-width: 600px) {
    #login-container {
        width: 100%;
        padding: 20px;
    }

    #login-title{
        margin-top: 2%;
    }

    #create-account-container {
        width: 100%;
        margin-top: 3%;
    }
}