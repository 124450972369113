h1 {
}

html, body { 
  height: 100%;
}

.preselectContainer {
  display: inherit;
  margin-left: 1em;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
  color: #3d405b;
}


.logoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
  
}

.topPlayers {
  list-style: none;
  margin: .25em;
}

.container {
  display: flex;
  touch-action: none;
  width: 100%;
  height: 100%;
  margin: 1rem auto;
  justify-content: center;
  justify-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 55vw;
  margin: 1em auto;
  background-color: #f3f6f4;
  padding: 2em;
  border-radius: 1em;
  color: #32383d;
  overflow: scroll;
  margin: 1em;
}

.submitForm {
  align-items: center;
  justify-content: center;
  width: 95%;
}

.submitForm button {
  width: 100%;
  margin: 1em;
  cursor: pointer;
}

.form input, textarea {
  border: 1px solid #ccc;
  border-radius: 1em;
  margin-bottom: 1vh;
  font-size: 1rem;
  resize: none;
  width: 95%;
  padding: 1vh;
}

.form button {
  padding: 0.5rem 1rem;
  background-color: #006666;
  color: #fff;
  border: none;
  border-radius: 1em;
  font-size: 1rem;
  cursor: pointer;
  transition: .3s ease-in;
  font-weight: 700;
}

.form button:hover {
  background-color: #9dbcbc;
  color: #32383d;
}

.formHeader {
  text-align: center;
  margin: .25em;
  color: #32383d;
}

.colorsContainer {
  display: inline-block;
  display: flex;
  justify-content: center;
}

.colorBlock {
  float: left;
  margin: 5px;
  border: 2px solid rgba(0, 0, 0, .4);
}

.signinButton {
  border: none;
  padding: 5px;
  border-radius: 5px;
  float: right;
  margin-right: 25px;
  transition: ease-in .2s;
}

.signinButton:hover {
  color: #4b5f7e;
}

.header {
  display: flex;
  align-items: center;
  background-color: #3d405b;
  color: #f3f6f4;
  padding: 5px;
}

.title {
  font-family: 'Ultra';
}

.title h1 {
  margin: 0;
}