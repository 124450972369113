.players {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: white;
    margin: 10px;
    padding: 15px;
    border-radius: 20px;
    height: min-content;
  }
  
  .playerContainer {
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

  .forwards {
    width: calc(33.33% - 10px);
    background-color: #f4f8ff;
  }

  .defense {
    width: calc(50% - 10px);
    background-color: #e3eeff;
  }

  .goalies {
    width: calc(50% - 10px);
    background-color: #f4f8ff;
  }

  .bench {
    width: calc(15% - 10px);
    background-color: #e3eeff;
  }
  
  .playerContainer p {
    margin: 0;
    padding: 5px;
    font-weight: bold;
  }

  .benchHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
