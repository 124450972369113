.logos {
    display: flex;
    width: 100%;
    place-items: center;
    text-align: center;
    padding: 1em;
    margin-top: 1em;
}

.imageContainer {
    position: relative;
}

.icons{
    list-style: none;
}
.icons li {
    height: 10em;
    width: 10em;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    position: relative;
}
  
.icons li:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 10%;
    transition: all 0.3s ease-in;
}
  
.icons li:hover:before{
    transform: rotate(360deg);
    border-radius: 100%;
}

.iconContainer {
    width: 100%;
    height: 80vh;
    overflow-y: scroll;
    background-color: #d6d6d6;
    border-radius: 4em;
}

.icons img {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, 50%);
    object-fit: contain;
}