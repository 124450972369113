html {
    height: 100%;
}

.calendarHolder {
    width: 100%;
}
.pageContainer {
    width: 100%;
    display: flex;
}

.rowData {
    padding: 1em;
    cursor: pointer;
}

.rowData:hover {
    color: #E07A5F;
}

.statsContainer {
    overflow:hidden;
    overflow-y: scroll;
    height: 60vh;
}

.playerCard {
    text-align: center;
    width: 90%;
}

.playerCardHeader {
    text-align: center;
}

.tableHeadings {
    background-color: #cadeff;
}
.fixed {
    top: 0;
    z-index: 2;
    position: sticky;
}

.tableHeadings th, td {
    padding: .75em;
}

.teamPerformanceContainer { 
    width: 100%;
    justify-content: center;
    display: flex;
}

.teamNameContainer {
    width: 75%;
}

.statsTable  {
    width: 100%;
    height: 100%;
    color: black;
}

.listItem0 {
    background-color: #f4f8ff;
}
.listItem1 {
    background-color: #ffffff;
}

.gameplayNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
  
.gameplayElement {
    margin: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: calc(33.33% - 10px);
    background-color: #b8d2ff;
    cursor: pointer;
    transition: .2s ease-in;
}
  
.gameplayElement p {
    margin: 0;
    padding: 5px;
    font-weight: bold;
}

.gameplayElement:hover {
    color: white;
}

.overallContainer h1 {
    font-size: 20px;
}