.player-card {
    position: relative;
    width: 200px;
    height: 300px;
    border: 1px solid black;
  }
  
  .player-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .player-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .player-details {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    padding: 10px;
  }
  
  .player-overall {
    position: absolute; /* Add position absolute */
    top: 10px; /* Position at the top */
    left: 10px; /* Position at the left */
    color: #fff;
    padding: 7px;
    font-weight: bold;
    border-radius: 50%;
  }
  
  .player-name {
    font-weight: bold;
  }
  
