body {
  background-color: #EDEEF0;
  color: #3d405b;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  width: 100%;
  height: 100%;
}

/* Primary colors */
.primary-color {
  color: #E0E0E0;
  background-color: #2C2C2C;
}

/* Secondary colors */
.secondary-color {
  color: #6a9681;
  font-weight: 500;
  font-family: 'Ultra';
}

.Season {
  height: 100%;
}


/* Accent colors */
.accent-color {
  color: #E0E0E0;
}

/* Success colors */
.success-color {
  color: #E0E0E0;
  background-color: #4CAF50;
}

/* Error colors */
.error-color {
  color: #E0E0E0;
  background-color: #E74C3C;
}

.topPlayers {
  list-style: none;
  margin: .25em;
}

.icons{
  list-style: none;
}
.icons li{
  height: 5em;
  width: 5em;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
}

.icons li:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10%;
  transition: all 0.3s ease-in;
}

.icons li:hover:before{
  transform: rotate(360deg);
  border-radius: 100%;
}

.imageContainer {
  position: relative;
}

.icons img {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, 50%);
  object-fit: contain;
}

.table {
  margin-left: auto;
  margin-right: auto;
}

.button {
  appearance: button;
  background-color: #D95D3A;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 .3em;
  box-sizing: border-box;
  color: #3d405b;
  cursor: pointer;
  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: .15em;
  line-height: 1.35em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: .75em 1em;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  max-width: 20em;
  min-width: fit-content;
}

.button:after {
  background-clip: padding-box;
  background-color: #e07a5f;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button:main,
.button:focus {
  user-select: auto;
}

.button:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button:disabled {
  cursor: auto;
}

.playerOverall {
}

.buttonPadding {
  margin-top: 15px;
}

.hoverAbove {
  z-index: 1
}
.glow {
  z-index:0;
  transform: scale(1.10) translate(-50%, -50%);
  -webkit-transform: scale(2) translate(-50%, -50%);
  filter: brightness(0) invert(1) blur(10px);
  -webkit-filter: brightness(0) invert(1) blur(10px);
  opacity:1;
}