* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent; /*for removing the blue color layer when clicked on mobile devices*/
  }
  header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 250;
    height: var(--header-height);
    width: 100%;
  }
  .header {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
  }
  .header .logo {
    padding-left: 40px;
    align-items: center;
  }

  .header .logo img {
    width: 50px;
  }
  .header .menu .drawer {
    display: flex;
    height: var(--header-height);
    padding-right: 100px;
  }


  .header .menu .drawer p {
    text-transform: capitalize;
    transition: all 0.1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    color: #fff;
    font-size: 1.1em;
    font-weight: 700;
    text-align: center;
  }
  .header .menu .drawer p:hover {
    color: #bcbcbc;
    cursor: pointer;
  }
  .header .menu .drawer a:active {
    background: #e0e0e0;
  }
  .menu-btn,
  .close-btn {
    background: transparent;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    left: 11px;
    top: 8px;
    display: none;
    cursor: pointer;
  }
  .menu-btn {
    left: 3px;
  }
  .close-btn:active,
  .menu-btn:active {
    background: #f3f3f3;
  }
  .img{
   height:calc(100vh - 53px);
   width:100%;
    object-fit:cover;
    display:block;
  }
  @media (max-width: 1140px) {
    .header .menu .drawer {
      padding: 0;
    }
  }
  .body {
    overflow: hidden;
  }
  @media (max-width: 920px) {
    .header .menu {
      display: flex;
      position: fixed;
      top: 0;
      left: -925px;
      width: 100%;
      height: 100%;
      z-index: 300;
    }
    .header .menu .blank {
      height: 100%;
      flex: 1;
    }
    .header .menu .drawer {
      padding-top: 50px;
      height: 100%;
      width: 225px;
      background: #fff;
      flex-direction: column;
      position: fixed;
      left: -240px;
      transition: all 200ms linear;
      overflow: auto;
    }
    .header .menu .drawer a {
      padding: 20px;
    }
    .menu-btn,
    .close-btn {
      display: block;
    }
    .header .logo {
      margin-left: 17px;
    }
    .drawer-visible {
      left: 0 !important;
    }
    .blur {
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(15px);
      left: 0 !important;
    }
  }


  .rightElement {
    margin-right: 15px;
  }

  .toggle {
    background-color: #f4f8ff;
    color: black;
  }

  .toggle:hover {
    background-color: #e3eeff;
    color: black;
  }