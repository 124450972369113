.openedPlayers {
    display: inline-block;
    padding: 10px;
}

.playerCardContainer {
    float:left;
    margin: 5px;
}

.pack {
    background-color: grey;
    height: 250px;
    width: 200px;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.pack h1 {
    font-size: 30px;
}